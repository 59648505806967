import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/global.css";
import "../styles/earthrise.css";
import "../styles/earthrise-portfolio.css";
import JSONData from "../content/portfolio-items.json";
import Font1 from "../../static/fonts/made_evolve_sans_medium-webfont.woff2";
import Font2 from "../../static/fonts/made_evolve_sans_regular-webfont.woff2";

const filters = Array.from(new Set(JSONData.reduce((acc, curr) => acc.concat(curr.tags), []))).sort();

// markup
const PortfolioPage = () => {
	const [tagFilter, setTagFilter] = React.useState();
	const switchFilter = (tag) => {
		setTagFilter(tagFilter == tag ? null : tag);
	};
	const Tag = ({ name }) => (
		<div className={"er-filter" + ((tagFilter == null || tagFilter == name) ? "" : " er-filter-disabled")} key={name} onClick={switchFilter.bind(null, name)}>{name}</div>
	);
	return (
		<>
			<Helmet>
				<title>Earthrise Tech - Portfolio</title>
				<meta name="description" content="Earthrise Tech has built a portfolio of innovative technical products in earth sciences and urban development, demonstrating competencies in a wide range of skills and fields." />
				<link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={Font1} />
				<link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={Font2} />
			</Helmet>
			<main className="er-main">
				<div id="erp-frame-master">
					<a className="er-a er-title-mini" href="/">Earthrise</a>
					<div className="er-title">Portfolio</div>
					<div id="erp-frame-scrolly">
						<div id="erp-frame-grid">
							{JSONData
								.map(e => (
									<div
										className={"erp-item" + ((tagFilter == null || e.tags.includes(tagFilter)) ? "" : " erp-item-hidden")}
										key={e.title}
									>
										<div className="erp-item-title">{e.title}</div>
										<div className="erp-item-client">For: {e.client}</div>
										<div className="erp-item-description">{e.description}</div>
										<div className="er-filterlist">
											{e.tags.map(t => <Tag name={t} />)}
										</div>
									</div>
								))}
						</div>
					</div>
					<div className="er-filterlist">
						{filters.map(e => (
							<Tag name={e} />
						))}
					</div>
				</div>
			</main>
		</>
	)
}

export default PortfolioPage
